<!--
  -- Created by zed on 2022/3/3
  -- Copyright © 2017 云宠智能 All rights reserved.
  --
  -- 饼状统计图表
-->
<template>
  <div class='pie-chart__container'>
    <div ref='chart' :style="{ 'height': chartHeight, 'width': width }" />
  </div>
</template>

<script>
const echarts = require('echarts')

export default {
  name: 'pieChart',
  props: {
    /* 图表宽度; [宽度CSS值] */
    width: { type: [String, Number], default: '100%' },
    /* 图表高度；[高度CSS值] */
    height: { type: [String, Number], default: '400px' },
    barHeight: { type: [String, Number], default: 10 },
    /* 提示框文本 */
    tooltip: { type: String, default: '饼状图' },
    /* 动画时长 */
    duration: { type: Number, default: 1000 },
    /** 图标指引线 */
    labelnormalShow: { type: Boolean, default: true }
  },
  data() {
    return {
      chart: null,
      chartWidth: this.width,
      chartHeight: this.height,
      dataSources: [],
      padding: 12
    }
  },
  beforeDestroy() {
    this.deallocChart()
  },
  mounted() {
    window.addEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      if (this.chart) {
        this.chart.resize();
      } else {
        console.log('请重写resize方法');
      }
    },
    /**
     * 配置图表
     * @param dataSource 数据源；值为 [{ name: 标题，value: 数据 }]
     */
    configChart(dataSource) {
      this.deallocChart()
      this.dataSources = Object.assign(dataSource, {})
      let autoHeight = `${this.dataSources.length * this.barHeight}px`
      this.chartHeight = this.height === 'auto' ? this.dataSources.length * this.barHeight > 400 ? autoHeight : '400px' : this.height
      this.$nextTick(() => {
        this.initChart()
        this.addPieceClickEvent()
      })
    },
    // 初始化图表
    initChart() {
      console.log("初始化饼状图表")
      this.chart = echarts.init(this.$refs.chart)
      this.chart.setOption({
        // 提示框配置
        tooltip: {
          trigger: 'item',
          formatter: "{a}<br/>{b}:{c} ({d}%)",
          backgroundColor: '#FFF',
        },
        // 说明配置
        legend: {
          top: '5%',
          left: 'left',
        },
        // 布局配置
        grid: {
          top: `${this.padding}px`,
          left: `${this.padding}px`,
          right: `${this.padding}px`,
          bottom: `${this.padding}px`,
          containLabel: true
        },
        // 序列配置（划饼）
        series: [{
          name: this.tooltip,
          type: 'pie',
          radius: '50%',
          top: `${Math.round(this.dataSources.length / 4) * this.barHeight}px`,
          label: {
            normal: {
              formatter: '{b}:{c} ({d}%)',
              show: this.labelnormalShow,
            }
          },
          labelLine: {  // 饼图横线
            normal: {
              show: true
            }
          },
          data: this.dataSources,
          emphasis: {   // 强调效果（鼠标悬浮时）
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          animationDuration: this.duration
        }]
      })
    },
    // 销毁图表
    deallocChart() {
      if (!this.chart) { return }
      console.log("销毁图表")
      this.chart.dispose()
      this.chart = null
    },
    addPieceClickEvent() {
      this.chart.on('click', (params) => {
        let selectedIndex = params.dataIndex
        if (selectedIndex === null || !this.dataSources) { return }
        let selectedData = this.dataSources[selectedIndex]
        this.$emit('onPieceClick', {
          index: selectedIndex,
          id: selectedData.id,
          name: selectedData.name,
          value: selectedData.value,
        })
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.pie-chart__container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
