<!--
  -- Created by zed on 2022/11/18
  --
  -- 用户统计
-->
<template>
  <div class='dashboard__wrap'>
    <!-- 用户分析 -->
    <el-row :gutter='32'>
      <el-col :lg='6'>
        <div class="chart-wrapper">
          <el-row  :gutter='32' type="flex" justify="space-between" align="middle">
            <el-col>
              <div class="grid-content">
                <div class='total-title'>{{ totalUsers }}</div>
                <div class='total-tip'>用户总量</div>
              </div>
            </el-col>
            <el-col>
              <div>
                <moe-image src="https://cloudpets-dev.oss-cn-shenzhen.aliyuncs.com/Management/dashboard-bg01.png" class="total-bg"/>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :lg='6'>
        <div class="chart-wrapper">
          <el-row :gutter='32' type="flex" justify="space-between" align="middle">
            <el-col>
              <div class="grid-content">
                <div class='total-title'>{{ yesterdayUsers }}</div>
                <div class='total-tip'>昨日增长量</div>
              </div>
            </el-col>
            <el-col>
              <div>
                <moe-image src="https://cloudpets-dev.oss-cn-shenzhen.aliyuncs.com/Management/dashboard-bg02.png" class="total-bg" />
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :lg='6'>
        <div class="chart-wrapper">
          <el-row :gutter='32' type="flex" justify="space-between" align="middle">
            <el-col>
              <div class="grid-content">
                <div class='total-title'>{{ weekUsers }}</div>
                <div class='total-tip'>本周增长量</div>
              </div>
            </el-col>
            <el-col>
              <div>
                <moe-image src="https://cloudpets-dev.oss-cn-shenzhen.aliyuncs.com/Management/dashboard-bg03.png" class="total-bg" />
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :lg='6'>
        <div class="chart-wrapper">
          <el-row :gutter='32' type="flex" justify="space-between" align="middle">
            <el-col>
              <div class="grid-content">
                <div class='total-title'>{{ monthUsers }}</div>
                <div class='total-tip'>本月增长量</div>
              </div>
            </el-col>
            <el-col>
              <div>
                <moe-image src="https://cloudpets-dev.oss-cn-shenzhen.aliyuncs.com/Management/dashboard-bg04.png" class="total-bg" />
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 用户数据增长 -->
    <el-row justify="center">
      <el-col>
        <div class="chart-wrapper">
          <div class='chart-title'>新用户增长趋势</div>
          <line-chart ref='userVarietyBarChartDay' direction='vertical' width='100%' height='400px' tooltip='增长数量' />
        </div>
      </el-col>
    </el-row>
    <!-- 用户分析 -->
    <el-row :gutter='32'>
      <el-col :lg='12'>
        <div class='chart-wrapper'>
          <div class='chart-title'>用户性别分析</div>
          <pie-chart ref='userSexPieChart' width='100%' height='400px' tooltip='用户性别' />
        </div>
      </el-col>
      <el-col :lg='12'>
        <div class="chart-wrapper">
          <div class='chart-title'>用户年龄范围分析</div>
          <pie-chart ref='userAgePieChart' width='100%' height='400px' tooltip='年龄范围' />
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import LineChart from './components/lineChart';
import PieChart from './components/PieChart';

export default {
  name: 'PetBoard',
  components: { LineChart, PieChart },
  data() {
    return {
      monthUsers: 0,      //本月新增
      totalUsers: 0,      //用户总数
      weekUsers: 0,       //本周新增
      yesterdayUsers: 0,  //昨日新增
    }
  },
  mounted() {
    this.getUserCensus();
    this.getSevenDaysUsers();
    this.setupUser();
  },
  methods: {
    /** 新*用户统计 */
    async getUserCensus() {
      let { code, result } = await this.$moe_api.STATISTICS_API.getUserCensus();
      if (code === 200) {
        const { monthUsers, totalUsers, weekUsers, yesterdayUsers } = result;
        this.monthUsers = monthUsers;
        this.totalUsers = totalUsers;
        this.weekUsers = weekUsers;
        this.yesterdayUsers = yesterdayUsers;
      }
    },
    /** 获取最近七天用户统计数据 */
    async getSevenDaysUsers() {
      let { code, result } = await this.$moe_api.STATISTICS_API.getSevenDaysUsers();
      if (code === 200) {
        this.$refs.userVarietyBarChartDay.configChart(result.map(({ date, num }) => {
          return {
            id: null,
            name: date,
            value: num,
          }
        }));
      }
    },
    async setupUser() {
      let { code, result } = await this.$moe_api.STATISTICS_API.readUser();
      if (code === 200) {
        const { sexGroup, ageRangeGroup } = result;
        this.$refs.userSexPieChart.configChart(sexGroup.map(item => {
          return {
            id: null,
            name: item.name,
            value: item.value,
          }
        }));
        this.$refs.userAgePieChart.configChart(ageRangeGroup.map(item => {
          return {
            id: null,
            name: item.name,
            value: item.value,
          }
        }));
      }
    },
  }
}
</script>

<style lang='scss' scoped>
.total-title{
  font-size: 40px;
  font-weight: bold;
  text-align: left;
}
.total-tip {
  text-align: left;
  color: #999999;
  font-size: 16px;
}
.total-bg{
  max-width: 100%;
  max-height: 100%;
  display: block;
  float: right;
}
.dashboard__wrap {
  padding:18px 16px;
}
.chart-title {
  text-align: center;
  margin-bottom: 16px;
}
.chart-subtitle {
  text-align: center;
  font-size: 14px;
  color: #999999;
  margin-top: 2px;
}
.chart-wrapper {
  font-size: 18px;
  text-align: center;
  background: white;
  padding: 16px;
  margin-bottom: 32px;
}
::v-deep .tab-center{
  text-align: center;
  .el-tabs__nav-wrap::after{
    height: 0;
  }
  .el-tabs__nav {
    float: none;
  }
  .el-tabs__active-bar{
    height: 0;
  }
}
</style>
