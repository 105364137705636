<!--
  -- Created by zed on 2022/11/18
  --
  -- 柱状统计图表
-->
<template>
  <div class='line-chart__container'>
    <div ref='chart' :style="{ height: chartHeight, width: chartWidth }" />
  </div>
</template>

<script>
const echarts = require('echarts')

export default {
  name: 'LineChart',
  props: {
    /* 图表方向；[ vertical(竖直) | horizontal(水平) ] */
    direction: { type: String, default: 'vertical' },
    /* 图表宽度; [ auto(自动计算，水平时可用) | 高度CSS值 ] */
    width: { type: [String, Number], default: 'auto' },
    /* 图表高度；[ auto(自动计算， 竖直时可用) | 高度CSS值 ] */
    height: { type: [String, Number], default: '360px' },
    /* 柱形宽度；水平且宽度auto时生效；否则取width值 */
    barWidth: { type: Number, default: 64 },
    /* 柱形高度；水平且高度为auto时生效；否则取height值*/
    barHeight: { type: Number, default: 40 },
    /* 柱形颜色；填写CSS颜色值 */
    barColor: { type: String, default: '#00B2DE' },
    /* 提示框文本 */
    tooltip: { type: String, default: null },
    /* 动画时长 */
    duration: { type: Number, default: 1000 },
  },
  data() {
    return {
      chart: null,
      chartWidth: this.width,
      chartHeight: this.height,
      dataSources: [{
        id: null,     // ID值
        name: null,   // 主轴描述（柱形竖直时为X轴）
        value: null,  // 交叉轴值（柱形竖直时为Y轴）
      }],
      xAxis: {},
      yAxis: {},
      padding: 12
    }
  },
  beforeDestroy() {
    this.deallocChart()
  },
  mounted() {
    window.addEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      if (this.chart) {
        this.chart.resize();
      } else {
        console.log('请重写resize方法');
      }
    },
    /**
     * 配置图表
     * @param dataSource 数据源； {
     *    id: null,     // ID值
     *    name: null,   // 主轴描述（柱形竖直时为X轴）
     *    value: null,  // 交叉轴值（柱形竖直时为Y轴）
     * }
     */
    configChart(dataSource) {
      this.deallocChart()
      this.dataSources = Object.assign(dataSource, {})
      if (this.direction === 'vertical') {
        this.xAxis = {
          type: 'category',
          data: this.dataSources.map(item => item.name),
          axisLabel: {    // 刻度标签（x轴的显示文本）
            // color: '#ff00ff',
            interval: 0,  // 间距几项隐藏刻度标签；0即全部都显示
          },
        }
        this.yAxis = { type: 'value' }
        let autoWidth = `${this.dataSources.length * this.barWidth}px`
        this.chartWidth = this.width === 'auto' ? autoWidth : this.width
        this.chartHeight = this.height
      } else if (this.direction === 'horizontal') {
        this.xAxis = { type: 'value' }
        this.yAxis = {
          type: 'category',
          data: this.dataSources.map(item => item.name),
          axisLabel: {    // 刻度标签（x轴的显示文本）
            // color: '#ff00ff',
            interval: 0,  // 间距几项隐藏刻度标签；0即全部都显示
          },
        }
        this.chartWidth = this.width
        let autoHeight = `${this.dataSources.length * this.barHeight}px`
        this.chartHeight = this.height === 'auto' ? autoHeight : this.height
      } else {
        console.error("【BarChart】参数`direction`配置有误")
      }
      this.$nextTick(() => {
        this.initChart()
        this.addBarClickEvent()
        this.addAxisClickEvent()
      })
    },
    // 初始化图表
    initChart() {
      console.log("初始化柱状图表")
      this.chart = echarts.init(this.$refs.chart)
      this.chart.setOption({
        color: this.barColor,
        // 提示框配置
        tooltip: {
          trigger: 'axis',    // 触发类型；[axis(轴触发)]
          axisPointer: {      // 轴选中指示类型
            type: 'shadow',   // 选中样式，[line|shadow]
          },
          backgroundColor: '#FFF',
        },
        // 布局配置
        grid: {
          top: `${this.padding}px`,
          left: `${this.padding}px`,
          right: `${this.padding}px`,
          bottom: `${this.padding}px`,
          containLabel: true
        },
        // X轴配置
        xAxis: [this.xAxis],
        // Y轴配置
        yAxis: [this.yAxis],
        // 序列配置（单柱拆多行）
        series: [{
          type: 'line',
          barWidth: '76%',
          name: this.tooltip,
          data: this.dataSources.map(item => item.value),
          animationDuration: this.duration
        }]
      })
    },
    // 销毁图表
    deallocChart() {
      if (!this.chart) { return }
      console.log("销毁图表")
      this.chart.dispose()
      this.chart = null
      this.chartWidth = 0
      this.chartHeight = 0
    },
    // 添加图表柱点击事件
    addBarClickEvent() {
      this.chart.on('click', (params) => {
        let selectedIndex = params.dataIndex
        if (!selectedIndex || !this.dataSources) { return }
        let selectedData = this.dataSources[selectedIndex]
        this.$emit('onBarClick', {
          index: selectedIndex,
          id: selectedData.id,
          name: selectedData.name,
          value: selectedData.value
        })
      })
    },
    // 添加图表轴点击事件
    addAxisClickEvent() {
      this.chart.getZr().on('click', (params) => {
        const pointInPixel = [params.offsetX, params.offsetY]
        if (!this.chart.containPixel('grid', pointInPixel)) { return }
        let selectedIndex = null
        if (this.direction === 'vertical') {
          // 实测seriesIndex或gridIndex均可成功取得数组类型的索引值；参数0为x轴，参数1为y轴
          let xAxisIndex = this.chart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0]
          selectedIndex = xAxisIndex
        } else if (this.direction === 'horizontal') {
          let yAxisIndex = this.chart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[1]
          selectedIndex = yAxisIndex
        }
        if (typeof selectedIndex !== 'number') { return }
        if (this.dataSources && this.dataSources[selectedIndex]) {
          let selectedData = this.dataSources[selectedIndex]
          this.$emit('onAxisClick', {
            index: selectedIndex,
            id: selectedData.id,
            name: selectedData.name,
            value: selectedData.value,
          })
        }
      })
    },
    // 获取图表尺寸
    getChartSize() {
      return { width: this.chartWidth, height: this.chartHeight }
    }
  }
}
</script>

<style lang='scss' scoped>
.bar-chart__container {
  width: 100%;
  height: 100%;
}
</style>
